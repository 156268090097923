


















import Vue from "vue";
import ProjectCard from "../components/browse/ProjectCard.vue";
import { Project } from "../types/project";

export default Vue.extend({
  name: "ProjectGrid",
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateSelectedProject(project: Project) {
      this.$emit("show-details", project);
    },
    filterByTag(tag: string) {
      this.$emit("tag-filter", tag);
    },
  },
  components: {
    ProjectCard,
  },
});
