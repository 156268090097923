

































import { Vue, Component, PropSync } from "vue-property-decorator";
@Component({
  name: "ControlBar",
})
export default class ControlBar extends Vue {
  @PropSync("searchText", { type: String }) tempSearchText!: string;
  @PropSync("displayToggle", { type: Number }) tempDisplayToggle!: number;
}
