






































import Vue from "vue";
import { Project } from "../types/project";

export default Vue.extend({
  name: "ProjectList",
  data: () => ({
    defaultImage:
      "https://tecnne.com/wp-content/uploads/2020/02/OMA-Gwanggyo-tecnne....jpg",
  }),
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  methods: {
    seeMore(project: Project) {
      this.$emit("show-details", project);
    },
    getWindowWidth() {
      if (window.innerWidth > 400) return true;
      else return false;
    },
  },
});
