






























import Vue from "vue";
import firebase from "firebase/app";
import db from "../store/db";

export default Vue.extend({
  name: "About",
  data: () => ({
    description: "",
    imageSource: "",
  }),
  methods: {
    goHome() {
      this.$router.push("/");
    },
    async getDescription() {
      await db.general
        .doc("about")
        .get()
        .then((about) => {
          this.description = about.data().content;
        });
    },
  },
  mounted() {
    firebase
      .storage()
      .ref("project-assets/default/profile.jpg")
      .getDownloadURL()
      .then((url) => {
        return (this.imageSource = url);
      });
    this.getDescription();
  },
});
