













































import Vue from "vue";
import { Post } from "@/types/post";

export default Vue.extend({
  name: "ProjectCard",
  data: () => ({
    defaultImage:
      "https://tecnne.com/wp-content/uploads/2020/02/OMA-Gwanggyo-tecnne....jpg",
    currentFilter: "",
  }),
  props: {
    post: {
      type: Object as () => Post,
      required: true,
    },
  },
  methods: {
    seeMore() {
      this.$emit("show-details", this.post);
    },
    updateFilter(tag: string) {
      this.currentFilter = tag;
    },
    filterByTag(active: boolean) {
      if (active)
        setTimeout(() => this.$emit("tag-filter", this.currentFilter), 10);
      else {
        this.$emit("tag-filter", "");
      }
    },
  },
});
