
































































































import Vue from "vue";
import { Project } from "@/types/project";
import db from "../../store/db";
import firebase from "firebase/app";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default Vue.extend({
  name: "ManageProjects",
  components: { TiptapVuetify },
  data: () => ({
    project: {} as Project,
    date: new Date().toISOString().substr(0, 10),
    files: [] as File[],
    imageUrls: [] as any[],
    isLoading: false,
    extensions: [
      History,
      Blockquote,
      Underline,
      Strike,
      Italic,
      ListItem,
      Link,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  computed: {
    toolbarAttrs(): unknown {
      return this.$vuetify.theme.dark
        ? { color: "black", dark: true }
        : { color: "white", dark: false };
    },
  },
  methods: {
    sortFiles() {
      this.files.sort((a, b) =>
        a.name.localeCompare(
          b.name,
          navigator.languages[0] || navigator.language,
          { numeric: true, ignorePunctuation: true }
        )
      );
    },
    updatePreviews() {
      this.sortFiles();
      this.imageUrls = [];
      this.files.forEach((file) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", () => {
          if (!this.imageUrls.includes(fileReader.result))
            this.imageUrls.push(fileReader.result);
        });
      });
    },
    createProject() {
      this.isLoading = true;
      let key = "";
      this.project.date = firebase.firestore.Timestamp.fromDate(
        new Date(this.date)
      );
      this.project.gallery = [];
      db.projects
        .add(this.project)
        .then((data) => {
          key = data.id;
          return key;
        })
        .then((key) => {
          this.sortFiles();
          this.files.forEach((file) => {
            firebase
              .storage()
              .ref(`project-assets/${key}/${file.name}`)
              .put(file)
              .then((upload) => {
                upload.ref.getDownloadURL().then(async (url) => {
                  console.log("pushing url: ", url);
                  await db.projects.doc(key).update({
                    gallery: firebase.firestore.FieldValue.arrayUnion(url),
                  });
                });
              });
          });
        })
        .then(() => {
          this.isLoading = false;
          alert("Project created successfully!");
        })
        .catch((error) => {
          console.error("Error creating project: ", error);
        });
    },
  },
});
