
















import Vue from "vue";
import BlogCard from "./BlogCard.vue";
import { Post } from "../../types/post";

export default Vue.extend({
  name: "BlogView",
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateSelectedPost(blogPost: Post) {
      this.$emit("show-details", blogPost);
    },
    filterByTag(tag: string) {
      this.$emit("tag-filter", tag);
    },
  },
  components: {
    BlogCard,
  },
});
