



















































import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";

export default Vue.extend({
  name: "Menu",
  data: () => ({
    drawer: false,
    dark: false,
  }),
  computed: {
    height() {
      return window.innerHeight;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    getItems(): { title: string; icon: string }[] {
      let items = [
        { title: "Home", icon: "fa-solid fa-house" },
        { title: "About", icon: "fa-solid fa-circle-info" },
        { title: "Projects", icon: "fa-solid fa-bars-progress" },
      ];
      if (firebase.auth().currentUser) {
        items.push({ title: "Admin", icon: "fa-solid fa-toolbox" });
      }
      return items;
    },
    route(name: string) {
      let route = name.replace(/\s/g, "").toLowerCase();
      if (this.$router.currentRoute.name?.toLowerCase() != route)
        this.$router.push(`/${route}`);
    },
    adminClick() {
      let user = firebase.auth().currentUser;
      if (!user) this.route("login");
      else this.$store.dispatch("logout");
    },
    getAdminButton() {
      if (firebase.auth().currentUser) {
        return "Log Out";
      } else return "Admin";
    },
    toggleDark() {
      this.$emit("toggle-change", this.dark);
    },
    setTheme() {
      this.dark = this.$vuetify.theme.dark;
    },
  },
  mounted() {
    this.setTheme();
  },
});
