































import Vue from "vue";

export default Vue.extend({
  name: "Admin",
  methods: {
    route(name: string) {
      if (this.$router.currentRoute.name?.toLowerCase() != name)
        this.$router.push(name);
    },
  },
});
