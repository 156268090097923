





















































import Vue from "vue";
import { Project } from "@/types/project";

export default Vue.extend({
  name: "ProjectCard",
  data: () => ({
    defaultImage:
      "https://tecnne.com/wp-content/uploads/2020/02/OMA-Gwanggyo-tecnne....jpg",
    currentFilter: "",
  }),
  props: {
    project: {
      type: Object as () => Project,
      required: true,
    },
  },
  methods: {
    seeMore() {
      this.$emit("show-details", this.project);
    },
    updateFilter(tag: string) {
      this.currentFilter = tag;
    },
    filterByTag(active: boolean) {
      if (active)
        setTimeout(() => this.$emit("tag-filter", this.currentFilter), 10);
      else {
        this.$emit("tag-filter", "");
      }
    },
  },
});
