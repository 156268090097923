
































































































































import Vue from "vue";
import firebase from "firebase/app";

export default Vue.extend({
  name: "Home",
  data: () => ({
    imageSource: "",
    linkText:
      "Currently having fun as COO at <a href='https://e-verse.com' target=_blank> e-verse</a>",
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    route(name: string) {
      this.$router.push(name);
    },
  },
  mounted() {
    firebase
      .storage()
      .ref("project-assets/default/fran.jpg")
      .getDownloadURL()
      .then((url) => {
        return (this.imageSource = url);
      });
  },
});
