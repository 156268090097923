


















import { Component, Vue, Prop } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";

@Component
export default class ProviderLogin extends Vue {
  // PROPS
  @Prop() readonly toggleMode!: () => void;

  // DATA PROPERTIES
  btnLoadingGoogle = false;

  // METHODS
  async signInWithGoogle(): Promise<void> {
    this.btnLoadingGoogle = true;
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      this.$store.dispatch("login", provider);
    } catch (error) {
      alert(error);
      this.$router.push("/");
    }
  }
}
